//  icons
import {
  FiInstagram,
  FiMail,
  FiMapPin,
  FiCalendar,
} from 'react-icons/fi';
import {
  BsLinkedin,
  BsWhatsapp,
} from 'react-icons/bs';


// testimonial images
import TestiImage1 from './assets/img/testimonials/klinikpsikologvira-1.png';
import TestiImage2 from './assets/img/testimonials/klinikpsikologvira-2.png';
import TestiImage3 from './assets/img/testimonials/klinikpsikologvira-3.png';
import TestiImage4 from './assets/img/testimonials/klinikpsikologvira-4.png';
import TestiImage5 from './assets/img/testimonials/klinikpsikologvira-5.png';
import TestiImage6 from './assets/img/testimonials/klinikpsikologvira-6.png';
import TestiImage7 from './assets/img/testimonials/klinikpsikologvira-7.png';
import TestiImage8 from './assets/img/testimonials/klinikpsikologvira-8.png';

// services images
import Service1 from './assets/img/services/klinikpsikologvira-1.png';
import Service2 from './assets/img/services/klinikpsikologvira-2.png';
import Service3 from './assets/img/services/klinikpsikologvira-3.png';
import Service4 from './assets/img/services/klinikpsikologvira-4.png';
import Service5 from './assets/img/services/klinikpsikologvira-5.png';
import Service6 from './assets/img/services/klinikpsikologvira-6.png';
import Service7 from './assets/img/services/klinikpsikologvira-7.png';
import Service8 from './assets/img/services/klinikpsikologvira-8.png';
import Service9 from './assets/img/services/klinikpsikologvira-9.png';
import Service10 from './assets/img/services/klinikpsikologvira-10.png';
import Service11 from './assets/img/services/klinikpsikologvira-11.png';
import Service12 from './assets/img/services/klinikpsikologvira-12.png';
import Service13 from './assets/img/services/klinikpsikologvira-13.png';
import Service14 from './assets/img/services/klinikpsikologvira-14.png';


// services images
import Team1 from './assets/img/team/team1.jpeg';
import Team2 from './assets/img/team/team2.jpeg';
import Team3 from './assets/img/team/team3.jpeg';
import Team4 from './assets/img/team/team4.jpeg';
import Team5 from './assets/img/team/team5.jpeg';
import Team6 from './assets/img/team/team6.jpeg';
import Team7 from './assets/img/team/team7.jpeg';
import Team8 from './assets/img/team/team8.jpeg';

// navigation
export const navigation = [
  {
    name: 'Beranda',
    href: 'home',
  },
  {
    name: 'Tentang',
    href: 'about',
  },
  {
    name: 'Layanan',
    href: 'services',
  },
  {
    name: 'Testimoni',
    href: 'testimonials',
  },
  {
    name: 'Kontak',
    href: 'contact',
  }
];

// social
export const social = [
  {
    icon: <BsWhatsapp />,
    href: 'https://wa.me/+6281336129619',
  },
  {
    icon: <FiInstagram />,
    href: 'https://www.instagram.com/klinikpsikologvira/',
  },
  {
    icon: <BsLinkedin />,
    href: 'https://www.linkedin.com/in/vira-pusparani/',
  },
  {
    icon: <FiMail />,
    href: 'mailto:virapusparani1@gmail.com',
  },
];

export const teams = [
  {
    icon: Team1,
    name: 'Vira Pusparani',
    description:
      '',
  },
];
// teams
export const teamsMember = [
  // {
  //   icon: Team2,
  //   name: 'Konseling Pernikahan',
  //   description:
  //     '',
  // },
  {
    icon: Team3,
    name: 'Konseling Pernikahan',
    description:
      '',
  },
  {
    icon: Team4,
    name: 'Konseling Pra-Nikah',
    description:
      '',
  },
  {
    icon: Team5,
    name: 'Konseling Self-Improvement',
    description:
      '',
  },
  {
    icon: Team6,
    name: 'Konseling Self-Problem',
    description:
      '',
  },
  {
    icon: Team7,
    name: 'Konseling Anxiety / Panic Attack',
    description:
      '',
  },
  {
    icon: Team8,
    name: 'Konseling Bipolar Disorder',
    description:
      '',
  }
];

// services
export const services = [
  {
    icon: Service1,
    name: 'Konseling Pernikahan',
    description:
      '',
  },
  {
    icon: Service2,
    name: 'Konseling Pra-Nikah',
    description:
      '',
  },
  {
    icon: Service3,
    name: 'Konseling Self-Improvement',
    description:
      '',
  },
  {
    icon: Service4,
    name: 'Konseling Self-Problem',
    description:
      '',
  },
  {
    icon: Service5,
    name: 'Konseling Anxiety / Panic Attack',
    description:
      '',
  },
  {
    icon: Service6,
    name: 'Konseling Bipolar Disorder',
    description:
      '',
  },
  {
    icon: Service7,
    name: 'Konseling PTSD',
    description:
      '',
  },
  {
    icon: Service8,
    name: 'Konseling Depresi',
    description:
      '',
  },
  {
    icon: Service9,
    name: 'Konseling Parenting',
    description:
      '',
  },
  {
    icon: Service10,
    name: 'Konseling Pasca Melahirkan',
    description:
      '',
  },
  {
    icon: Service11,
    name: 'Pelatihan Leadership',
    description:
      '',
  },
  {
    icon: Service12,
    name: 'Konseling Setelah Melahirkan',
    description:
      '',
  },
  {
    icon: Service13,
    name: 'Pelatihan Team Work',
    description:
      '',
  },
  {
    icon: Service14,
    name: 'Pelatihan Self-Healing',
    description:
      '',
  },
];

// testimonials
export const testimonials = [
  {
    authorImg: TestiImage1,
    authorText:
      '',
    authorName: '',
    authorPosition: '',
  },
  {
    authorImg: TestiImage2,
    authorText:
      '',
    authorName: '',
    authorPosition: '',
  },
  {
    authorImg: TestiImage3,
    authorText:
      '',
    authorName: '',
    authorPosition: '',
  },
  {
    authorImg: TestiImage4,
    authorText:
      '',
    authorName: '',
    authorPosition: '',
  },
  {
    authorImg: TestiImage5,
    authorText:
      '',
    authorName: '',
    authorPosition: '',
  },
  {
    authorImg: TestiImage6,
    authorText:
      '',
    authorName: '',
    authorPosition: '',
  },
  {
    authorImg: TestiImage7,
    authorText:
      '',
    authorName: '',
    authorPosition: '',
  },
  {
    authorImg: TestiImage8,
    authorText:
      '',
    authorName: '',
    authorPosition: '',
  },
];

// contact
export const contact = [
  {
    icon: <BsWhatsapp />,
    title: 'Konseling Sekarang',
    subtitle: 'Hubungi saya di Nomor WA',
    description: '+62 813-3612-9619',
  },
  {
    icon: <FiMail />,
    title: 'Layanan Informasi',
    subtitle: 'Hubungi saya lewat Email',
    description: 'klinikpsikologvira@gmail.com',
  },
  {
    icon: <FiMapPin />,
    title: 'Lokasi Klinik',
    subtitle: 'Perumahan Emerald Garden Blok A11/19,Meteseh, Tembalang.',
    description: 'Kota Semarang, Jawa Tengah 50271',
  },
  {
    icon: <FiCalendar />,
    title: 'Jadwal Praktek',
    subtitle: <>SENIN-SABTU <br/> PAGI: 10.00-12.00 WIB<br/>SIANG-MALAM: 13.00-20.00 WIB</>,
    description: 'MINGGU (Dengan Perjanjian)',
  },
];
